
import Vue from 'vue'

export default Vue.extend({
  name: 'FilterDatePicker',
  data () {
    return {
      from: '',
      to: '',
      pickerOptions: {
        disabledDate (time: Date) {
          return time.getTime() > Date.now();
        },
      }
    }
  }
})
