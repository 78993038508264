
import Vue from 'vue'

export default Vue.extend({
  name: 'FilterTextInput',
  props: {
    label: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      type: Object,
      required: true
    },
    prop: {
      type: String,
      required: true
    }
  }
})
