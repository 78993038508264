
import Vue, { PropType } from 'vue'
import { IDomeTransactionalTables } from '@/dome/types/IDomeTransactionalInfo'
import DetailsInfoTable from '@/dome/components/DomeTransactionalInfo/DetailsInfoTable.vue'
import MemoTimelineSection from '@/dome/container/DomeTransactionalInfo/MemoTimelineSection.vue'

export default Vue.extend({
  components: { DetailsInfoTable, MemoTimelineSection },
  props: {
    tableData: {
      type: Object as PropType<IDomeTransactionalTables>,
      required: true
    }
  },
  data() {
    return {
      titleToLokaliseObject: {
        '결제 정보': this.$t('dome.payment.information'),
        '거래 정보': this.$t('dome.transaction.info'),
        'Fraud 정보': this.$t('dome.fraud.info'),
        '상점 정보': this.$t('dome.store.info')
      }
    }
  }
})
