
import Vue, { PropType } from 'vue'
import AlertGraphSection from '@/dome/components/DomeTransactionalInfo/AlertGraphSection.vue'
import ConditionDescriptionSection from '@/dome/components/DomeTransactionalInfo/ConditionDescriptionSection.vue'
import { IDomeAlertModalProps } from '@/dome/types/IDomeAlertModal'
import {
  fraudDescription,
  disputeDescription,
  fraudConditionDescription,
  disputeConditionDescription
} from '@/dome/constants/AlertModal'

export default Vue.extend({
  components: { AlertGraphSection, ConditionDescriptionSection },
  props: {
    type: {
      type: String as PropType<'fraud' | 'dispute'>,
      required: true
    },
    value1: {
      type: String,
      required: true
    },
    value2: {
      type: String,
      required: true
    },
    searchedPeriod: {
      type: String,
      required: true
    }
  },
  data(): {
    descriptionObject: Dictionary<string>
    conditionDescriptionObject: Dictionary<string>
    modalPropsObject: Dictionary<Array<IDomeAlertModalProps>>
    modalProps: Array<IDomeAlertModalProps>
    colorObject: Dictionary<string>
  } {
    return {
      descriptionObject: {
        fraud: fraudDescription,
        dispute: disputeDescription
      },
      conditionDescriptionObject: {
        fraud: fraudConditionDescription,
        dispute: disputeConditionDescription
      },
      modalPropsObject: {
        fraud: [
          {
            title: `${this.$t('dome.fraud.alert.rate.a')}`,
            value: '',
            graphMaxValue: '0.65',
            graphUnit: '%',
            expressiveUnit: `${this.$t('dome.amount.usd')}`,
            width: '',
            color: ''
          },
          {
            title: `${this.$t('dome.fraud.fraud_alert.amount.b')}`,
            value: '',
            graphMaxValue: '50.00',
            graphUnit: 'K',
            expressiveUnit: `${this.$t('dome.amount.usd')}`,
            width: '',
            color: ''
          }
        ],
        dispute: [
          {
            title: `${this.$t('dome.dispute.dispute_alert.rate.a')}`,
            value: '',
            graphMaxValue: '0.90',
            graphUnit: '%',
            expressiveUnit: `${this.$t('dome.cases')}`,
            width: '',
            color: ''
          },
          {
            title: `${this.$t('dome.dispute.dispute_alert.number.b')}`,
            value: '',
            graphMaxValue: '100',
            graphUnit: `${this.$t('dome.number')}`,
            expressiveUnit: `${this.$t('dome.cases')}`,
            width: '',
            color: ''
          }
        ]
      },
      modalProps: [],
      colorObject: {
        fraud: '#FFA336',
        dispute: '#877CFF'
      }
    }
  },
  /**
   * created lifecycle에 modalPropsObject의 prop들을 modalProps에 할당하고, graphMaxValue 대비 value값의 비율을 계산하여 너비를 산출, prop.width에 저장한다.
   * 그래프 최대 너비는 458px, 이를 넘어갈 경우 prop.color를 경고 색상인 '#FF5B5B'로 설정한다.
   */
  created() {
    this.modalProps = this.modalPropsObject[this.type]

    this.modalProps[0].value = this.value1
    this.modalProps[1].value = this.value2

    this.modalProps.forEach(prop => {
      prop.width = this.calculateWidth(prop.value, prop.graphMaxValue)
      prop.color = Number(prop.width) >= 458 ? '#FF5B5B' : this.colorObject[this.type]
    })
  },
  methods: {
    /**
     * value와 max 값을 토대로 너비를 계산한다.
     *
     * 그래프 최대 너비는 458px, 458이 넘을경우 458을 리턴.
     *
     * @param {string} value 계산 대상 value
     * @param {string} max 계산 대상 max value
     *
     * @return {string} 계산된 값 string으로 변환 후 리턴. 458을 넘을 경우 '458' 리턴
     */
    calculateWidth(value: string, max: string): string {
      const numValue = Number(value)
      const numMax = Number(max)

      const width = (numValue / numMax) * 458

      return width > 458 ? '458' : `${width}`
    }
  }
})
