
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  FraudTransactionGetters,
  FraudTransactionActions, FraudTransactionMutations
} from '@/dome/store/fraudTransactionState'
import TransactionTable from '@/dome/components/fraudTransactions/TransactionTable.vue'
import { IFraudTransaction } from '@/dome/types/FraudTypes'
import { FraudStatus } from '@/dome/constants/FraudStatus'

export default Vue.extend({
  name: 'FraudTransactionsContainer',
  components: { TransactionTable },
  data () {
    return {
      page: 1
    }
  },
  computed: {
    ...mapGetters('fraudTransactionState', {
      fraudTransactions: FraudTransactionGetters.FraudTransactions,
      totalCount: FraudTransactionGetters.TotalFraudTransaction
    })
  },
  async created () {
    await this.getFraudTransactions()
  },
  methods: {
    ...mapActions('fraudTransactionState', {
      getFraudTransactions: FraudTransactionActions.FetchFraudTransactions,
      updateFraudStatus: FraudTransactionActions.UpdateFraudTransactionStatus
    }),
    ...mapMutations('fraudTransactionState', {
      setPage: FraudTransactionMutations.SetPage
    }),
    onClickTransaction (row: IFraudTransaction, column: { property?: string }) {
      if (column.property === 'change_status') return
      this.$emit('onClickTransaction', row.id)
    },
    async onChangePage () {
      this.setPage(this.page)
      await this.getFraudTransactions(this.page)
    },
    async changeStatus (param: { id: number, status: FraudStatus }) {
      await this.updateFraudStatus(param)
    }
  }
})
