import i18n from '@/plugins/i18n'

export const fraudDescription = `${i18n.t('dome.fraud.graph.detail')}`
export const disputeDescription = `${i18n.t('dome.dispute.graph.detail')}`
export const fraudConditionDescription = `${i18n.t('dome.fraud.graph.formula')}<br/><br/>${i18n.t(
  'dome.fraud.graph.example'
)}`
export const disputeConditionDescription = `${i18n.t('dome.dispute.graph.formula')}<br/><br/>${i18n.t(
  'dome.dispute.graph.example'
)}`
