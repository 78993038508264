import i18n from '@/plugins/i18n'

export enum FraudStatus {
  ALL = 0,
  FRAUD_PASS = 200,
  FRAUD_BLOCK = 210,
  DISPUTE_WON = 300,
  DISPUTE_LOST = 310,
  CHARGEBACK_ACCEPT = 400,
  CHARGEBACK_FRAUD = 410
}

export enum FraudTimelineActionStatus {
  ACTION_FRAUD_PASS = 10,
  ACTION_FRAUD_BLOCK = 20,
  ACTION_DISPUTE_WON = 30,
  ACTION_DISPUTE_LOST = 40,
  ACTION_CHARGEBACK_ACCEPT = 50,
  ACTION_CHARGEBACK_FRAUD = 60,
  ACTION_MEMO_CREATE = 100,
  ACTION_MEMO_UPDATE = 110
}

export enum PaymentType {
  ELECTRONIC_FUND_TRANSFER = 1,
  CASH,
  CHECK,
  CREDIT_CARD,
  CRYPTO_CURRENCY,
  DIGITAL_WALLET,
  IN_APP_PURCHASE,
  POINTS,
  VOUCHER,
  GIFT_CARD
}

// TODO locale 로 이관
export const PaymentTypeLabel = {
  [PaymentType.ELECTRONIC_FUND_TRANSFER]: `${i18n.t('general.transfer')}`,
  [PaymentType.CASH]: `${i18n.t('general.cash')}`,
  [PaymentType.CHECK]: `${i18n.t('general.cheque')}`,
  [PaymentType.CREDIT_CARD]: `${i18n.t('dome.credit.card')}`,
  [PaymentType.CRYPTO_CURRENCY]: `${i18n.t('general.blockchain')}`,
  [PaymentType.DIGITAL_WALLET]: `${i18n.t('general.digital_wallet')}`,
  [PaymentType.IN_APP_PURCHASE]: `${i18n.t('general.in_app_payment')}`,
  [PaymentType.POINTS]: `${i18n.t('general.point_payment')}`,
  [PaymentType.VOUCHER]: `${i18n.t('general.voucher_payment')}`,
  [PaymentType.GIFT_CARD]: `${i18n.t('general.gift_card')}`
}

export enum FraudAlertLabel {
  NONE = 0,
  NORMAL = 1,
  WARNING,
  DANGER,
  WATCHING
}
