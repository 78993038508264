import { FraudAlertLabel, FraudStatus, PaymentType } from '@/dome/constants/FraudStatus'
import { Dictionary } from 'vue-router/types/router'
import i18n from '@/plugins/i18n'

export const locales: Dictionary<Dictionary<string>> = {
  payment_type: {
    [PaymentType.ELECTRONIC_FUND_TRANSFER]: '이체',
    [PaymentType.CASH]: '현금',
    [PaymentType.CHECK]: '수표',
    [PaymentType.CREDIT_CARD]: `${i18n.t('dome.credit.card')}`,
    [PaymentType.CRYPTO_CURRENCY]: '가상화폐',
    [PaymentType.DIGITAL_WALLET]: '디지털 월렛',
    [PaymentType.IN_APP_PURCHASE]: '인앱결제',
    [PaymentType.POINTS]: '포인트결제',
    [PaymentType.VOUCHER]: '바우처결제',
    [PaymentType.GIFT_CARD]: '기프트카드'
  },
  systemStatus: {
    [FraudStatus.FRAUD_PASS]: 'PASS',
    [FraudStatus.FRAUD_BLOCK]: 'BLOCK',
    [FraudStatus.DISPUTE_WON]: 'PASS',
    [FraudStatus.DISPUTE_LOST]: 'BLOCK',
    [FraudStatus.CHARGEBACK_ACCEPT]: 'PASS',
    [FraudStatus.CHARGEBACK_FRAUD]: 'BLOCK'
  },
  fraudStatus: {
    [FraudStatus.FRAUD_PASS]: 'FRAUD PASS',
    [FraudStatus.FRAUD_BLOCK]: 'FRAUD BLOCK',
    [FraudStatus.DISPUTE_WON]: 'DISPUTE WON',
    [FraudStatus.DISPUTE_LOST]: 'DISPUTE LOST',
    [FraudStatus.CHARGEBACK_ACCEPT]: 'CHARGEBACK ACCEPT',
    [FraudStatus.CHARGEBACK_FRAUD]: 'CHARGEBACK FRAUD'
  },
  transactionColumns: {
    tid: `${i18n.t('dome.tid')}`,
    system_status: `${i18n.t('dome.system.result')}`,
    fraud_score: `${i18n.t('dome.fraud.score')}`,
    fraud_status: `${i18n.t('dome.transaction.status')}`,
    amount: `${i18n.t('dome.transaction.amount')}`,
    currency: `${i18n.t('dome.transaction.currency')}`,
    order_number: `${i18n.t('dome.transaction.number')}`,
    corp_name: `${i18n.t('dome.store.info')}`,
    seller_id: `${i18n.t('dome.seller.id')}`,
    merchandise_name: `${i18n.t('dome.product.name')}`,
    customer_name: `${i18n.t('dome.customer.name')}`,
    customer_email: `${i18n.t('commons.email')}`,
    card_number: `${i18n.t('dome.card.number')}`,
    created_at: `${i18n.t('dome.transaction.date')}`,
    payment_type: `${i18n.t('dome.payment.type')}`,
    fraud_code: `${i18n.t('dome.fraud.id')}`,
    reasons: `${i18n.t('dome.fraud.detail')}`
  },
  fraudAlert: {
    [FraudAlertLabel.NONE]: `${i18n.t('dome.set.store.name')}`,
    [FraudAlertLabel.NORMAL]: `${i18n.t('dome.normal')}`,
    [FraudAlertLabel.WARNING]: `${i18n.t('dome.attention')}`,
    [FraudAlertLabel.DANGER]: `${i18n.t('dome.warning')}`,
    [FraudAlertLabel.WATCHING]: `${i18n.t('dome.visa_monitoring')}`
  }
}
