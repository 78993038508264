
import DefaultLayout from '@/components/commons/DefaultLayout.vue'
import Vue from 'vue'
import BasicDialog from '@/components/commons/BasicDialog.vue'
import DomeTransactionDetailsModal from '@/dome/container/DomeTransactionalInfo/DomeTransactionDetailsModal.vue'
import FraudTransactionsContainer from '@/dome/container/FraudTransactionsContainer.vue'
import { FraudTransactionActions, FraudTransactionGetters } from '@/dome/store/fraudTransactionState'
import { mapActions, mapGetters } from 'vuex'
import { IFraudScoreReason, IFraudTransactionDetail } from '@/dome/types/FraudTypes'
import { IDomeTransactionalTables } from '@/dome/types/IDomeTransactionalInfo'
import { FraudStatus, PaymentTypeLabel } from '@/dome/constants/FraudStatus'
import dayjs from 'dayjs'
import LayoutSideButton from '@/dome/components/fraudTransactions/buttons/LayoutSideButton.vue'
import TransactionFilterContainer from '@/dome/container/TransactionFilterContainer.vue'
import SubDashboardContainer from '@/dome/container/SubDashboardContainer.vue'
import FraudExcelContainer from '@/dome/container/FraudExcelContainer.vue'

export default Vue.extend({
  components: {
    FraudExcelContainer,
    SubDashboardContainer,
    TransactionFilterContainer,
    LayoutSideButton,
    FraudTransactionsContainer,
    DefaultLayout,
    BasicDialog,
    DomeTransactionDetailsModal
  },
  data() {
    return {
      isPopoverOpen: false,
      isDetailsModalOpen: false,
      tableData: {},
      hasApplied: false,
      actionToStringObject: {
        [FraudStatus.ALL]: '',
        [FraudStatus.FRAUD_PASS]: `${this.$t('dome.fraud.pass')}`,
        [FraudStatus.FRAUD_BLOCK]: `${this.$t('dome.fraud.block')}`,
        [FraudStatus.DISPUTE_WON]: `${this.$t('dome.dispute.won')}`,
        [FraudStatus.DISPUTE_LOST]: `${this.$t('dome.dispute.lost')}`,
        [FraudStatus.CHARGEBACK_ACCEPT]: `${this.$t('dome.chargeback.accept')}`,
        [FraudStatus.CHARGEBACK_FRAUD]: `${this.$t('dome.chargeback.fraud')}`
      }
    }
  },
  computed: {
    ...mapGetters('fraudTransactionState', {
      fraudTransactionDetail: FraudTransactionGetters.FraudTransactionDetail
    }),
    filterIcon(): string {
      return this.hasApplied ? 'el-icon-success' : 'el-icon-s-operation'
    }
  },
  methods: {
    ...mapActions('fraudTransactionState', {
      fetchFraudTransactionDetail: FraudTransactionActions.FetchFraudTransactionDetail
    }),
    async onClickTransaction(id: number) {
      await this.fetchFraudTransactionDetail(id)
      this.tableData = this.convertDetailToTables(this.fraudTransactionDetail)
      this.isDetailsModalOpen = true
    },
    stringifyFraudReasons(reasons: Array<IFraudScoreReason>): string {
      let result: string = ''

      reasons.forEach(reason => {
        result += `${reason.name}:${reason.value}${reason.details.length ? `(${reason.details})` : ''}<br/>`
      })

      return result
    },
    makeDashWhenUndefined(value: string): string {
      return value.length ? value : '-'
    },
    convertDetailToTables(detail: IFraudTransactionDetail): IDomeTransactionalTables {
      return {
        '결제 정보': [
          {
            label: `${this.$t('dome.dome_transaction.amount')}`,
            value: `${this.makeDashWhenUndefined(detail.amount.toLocaleString())} ${this.makeDashWhenUndefined(
              detail.currency
            )}`,
            className: 'amount'
          },
          {
            label: `${this.$t('dome.transaction.status')}`,
            value: `${this.actionToStringObject[detail.fraud_status].toUpperCase()}`
          },
          { label: `${this.$t('dome.transaction.date')}`, value: `${dayjs(detail.created_at).format('YYYY-MM-DD')}` },
          { label: `${this.$t('dome.customer.name')}`, value: `${this.makeDashWhenUndefined(detail.customer_name)}` },
          { label: `${this.$t('commons.email')}`, value: `${this.makeDashWhenUndefined(detail.customer_email)}` },
          { label: `${this.$t('dome.payment.type')}`, value: `${PaymentTypeLabel[detail.payment_type]}` },
          {
            label: `${this.$t('dome.card.number')}`,
            value: `${detail.card_number.length ? detail.card_number.match(/.{1,4}/g)?.join(' ') : '-'}`
          }
        ],
        '거래 정보': [
          { label: 'TID', value: `${this.makeDashWhenUndefined(detail.tid)}` },
          {
            label: `${this.$t('dome.transaction.number')}`,
            value: `${this.makeDashWhenUndefined(detail.order_number)}`
          },
          {
            label: `${this.$t('dome.product.name')}`,
            value: `${detail.fraud_payment_items[0] ? detail.fraud_payment_items[0].name : '-'}`
          }
        ],
        'Fraud 정보': [
          { label: `${this.$t('dome.fraud.id')}`, value: `${this.makeDashWhenUndefined(detail.fraud_id)}` },
          {
            label: `${this.$t('dome.fraud.status')}`,
            value: `${this.actionToStringObject[detail.fraud_status].toUpperCase()}`
          },
          { label: `${this.$t('dome.dome_fraud.score')}`, value: `${Math.round(detail.fraud_score)}` },
          {
            label: `${this.$t('dome.fraud.detail')}`,
            value: '',
            details: `${this.stringifyFraudReasons(detail.fraud_score_reasons)}`
          }
        ],
        '상점 정보': [
          { label: `${this.$t('dome.store.name')}`, value: `${this.makeDashWhenUndefined(detail.seller_name)}` },
          { label: `${this.$t('dome.seller.id')}`, value: `${this.makeDashWhenUndefined(detail.seller_id)}` }
        ]
      }
    },
    setFilterApplied() {
      this.hasApplied = true
      this.isPopoverOpen = false
    },
    setFilterReset() {
      this.hasApplied = false
    }
  }
})
