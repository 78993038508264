
import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'FilterAmount',
  props: {
    amount: {
      type: Object as PropType<{ from: number; to: number; currency: string }>,
      required: true
    }
  }
})
