
import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'CheckIconContents',
  props: {
    systemStatus: {
      type: Number as PropType<number>,
      required: true
    },
    content: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    iconClass () {
      const icon = Number(this.systemStatus) % 100 === 0 ? 'el-icon-success' : 'el-icon-error'
      return `icon_content ${icon}`
    }
  }
})
