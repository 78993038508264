
import Vue from 'vue'
import FilterRadioGroup from '@/dome/components/filter/inputs/FilterRadioGroup.vue'
import dayjs, { Dayjs } from 'dayjs'
import FilterDatePicker from '@/dome/components/filter/inputs/FilterDatePicker.vue'

export default Vue.extend({
  name: 'FilterPeriodContainer',
  components: { FilterDatePicker, FilterRadioGroup },
  data() {
    return {
      value: {
        from: dayjs().startOf('day').toDate(),
        to: dayjs().endOf('day').toDate()
      },
      dates: {
        today: dayjs(),
        week: dayjs().add(-1, 'week'),
        months: dayjs().add(-3, 'month'),
        'direct.input': dayjs()
      },
      isManualInput: false,
      radioLabelLocale: {
        [`${this.$t('dome.today')}`]: 'today',
        [`${this.$t('dome.week')}`]: 'week',
        [`${this.$t('dome.months')}`]: 'months'
      }
    }
  },
  computed: {
    labels(): Array<string> {
      return Object.keys(this.dates).map(key => this.$t(`dome.${key}`) as string)
    }
  },
  methods: {
    onChange(value: string) {
      if (value === this.$t('dome.direct.input')) {
        this.isManualInput = true
        return
      }
      this.isManualInput = false
      const dates = this.dates as Dictionary<Dayjs>
      this.value.from = dates[this.radioLabelLocale[value]].startOf('day').toDate()
      this.$emit('onChange', this.value)
    },
    onChangeFrom(value: Date) {
      this.value.from = dayjs(value).startOf('day').toDate()
      this.$emit('onChange', this.value)
    },
    onChangeTo(value: Date) {
      this.value.to = dayjs(value).endOf('day').toDate()
      this.$emit('onChange', this.value)
    }
  }
})
