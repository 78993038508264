
import Vue from 'vue'

export default Vue.extend({
  props: {
    width: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: true
    }
  }
})
