
import Vue from 'vue'

export default Vue.extend({
  name: 'LayoutSideButton',
  props: {
    label: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: false,
      default: ''
    },
    isPlain: {
      type: Boolean,
      required: false,
      default: true
    }
  }
})
