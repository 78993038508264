
import { IDomeTransactionalInfoTable } from '@/dome/types/IDomeTransactionalInfo'
import Vue, { PropType } from 'vue'

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true
    },
    detailsInfo: {
      type: Array as PropType<Array<IDomeTransactionalInfoTable>>,
      required: true
    }
  },
  methods: {
    setClassName: function ({ row }: { row: IDomeTransactionalInfoTable }) {
      return row.details ? '' : 'no-expand'
    },
    onClickRow: function (row: any) {
      if (row.label === `${this.$t('dome.fraud.detail')}` && row.details.length) {
        ;(this.$refs.dataTable as any).toggleRowExpansion(row)
      }
    }
  }
})
