
import Vue, { PropType } from 'vue'
import { IMemoLine } from '@/dome/types/IMemoTimeLine'
import { FraudTimelineActionStatus } from '@/dome/constants/FraudStatus'
import { mapActions } from 'vuex'
import { FraudTransactionActions } from '@/dome/store/fraudTransactionState'

export default Vue.extend({
  props: {
    memoLine: {
      type: Object as PropType<IMemoLine>,
      required: true
    },
    isLast: {
      type: Boolean
    }
  },
  data() {
    return {
      isPopoverVisible: false,
      isInputShown: false,
      inputContent: '',
      actionClassName: {
        [FraudTimelineActionStatus.ACTION_FRAUD_PASS]: 'el-icon-success',
        [FraudTimelineActionStatus.ACTION_FRAUD_BLOCK]: 'el-icon-error',
        [FraudTimelineActionStatus.ACTION_DISPUTE_WON]: 'el-icon-success',
        [FraudTimelineActionStatus.ACTION_DISPUTE_LOST]: 'el-icon-error',
        [FraudTimelineActionStatus.ACTION_CHARGEBACK_ACCEPT]: 'el-icon-success',
        [FraudTimelineActionStatus.ACTION_CHARGEBACK_FRAUD]: 'el-icon-error',
        [FraudTimelineActionStatus.ACTION_MEMO_CREATE]: 'el-icon-document',
        [FraudTimelineActionStatus.ACTION_MEMO_UPDATE]: 'el-icon-document'
      },
      actionToContentObject: {
        [FraudTimelineActionStatus.ACTION_FRAUD_PASS]: `${this.$t('dome.fraud.pass')}`,
        [FraudTimelineActionStatus.ACTION_FRAUD_BLOCK]: `${this.$t('dome.fraud.block')}`,
        [FraudTimelineActionStatus.ACTION_DISPUTE_WON]: `${this.$t('dome.dispute.won')}`,
        [FraudTimelineActionStatus.ACTION_DISPUTE_LOST]: `${this.$t('dome.dispute.lost')}`,
        [FraudTimelineActionStatus.ACTION_CHARGEBACK_ACCEPT]: `${this.$t('dome.chargeback.accept')}`,
        [FraudTimelineActionStatus.ACTION_CHARGEBACK_FRAUD]: `${this.$t('dome.chargeback.fraud')}`,
        [FraudTimelineActionStatus.ACTION_MEMO_CREATE]: '',
        [FraudTimelineActionStatus.ACTION_MEMO_UPDATE]: ''
      }
    }
  },
  computed: {
    isMemo(): boolean {
      return (
        `${this.memoLine.action}` === `${FraudTimelineActionStatus.ACTION_MEMO_CREATE}` ||
        `${this.memoLine.action}` === `${FraudTimelineActionStatus.ACTION_MEMO_UPDATE}`
      )
    },
    showContent(): string {
      return this.isMemo ? this.memoLine.content : this.actionToContentObject[this.memoLine.action]
    }
  },
  methods: {
    ...mapActions('fraudTransactionState', {
      updateFraudTransactionMemo: FraudTransactionActions.UpdateFraudTransactionMemo,
      deleteFraudTransactionMemo: FraudTransactionActions.DeleteFraudTransactionMemo
    })
  }
})
