
import Vue from 'vue'
import FilterCheckbox from '@/dome/components/filter/inputs/FilterCheckbox.vue'
import FraudTooltip from '@/dome/components/FraudTooltip.vue'

export default Vue.extend({
  name: 'FilterDivision',
  components: { FraudTooltip, FilterCheckbox },
  props: {
    filterLabel: {
      type: String,
      required: true
    },
    isChecked: {
      type: Boolean,
      required: false,
      default: false
    },
    hasTooltip: {
      type: Boolean,
      required: false,
      default: false
    },
    tooltipContent: {
      type: String,
      required: false,
      default: ''
    }
  }
})
